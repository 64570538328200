<script>
import Layout from "@/router/layouts/main";

export default {
  locales: {
    pt: {
    },
    es: {
    }
  },
  components: {
    Layout,
  },
  data() {
    return {
      slide: 0,
      sliding: null,

      apns: [
        'consultor-club-elo-mobiliarios-1',
        'consultor-club-elo-mobiliarios-2',
        'consultor-club-elo-mobiliarios-3',
        'consultor-club-elo-mobiliarios-4',
        'consultor-club-elo-mobiliarios-5',
        'consultor-club-elo-mobiliarios-6',
        'consultor-club-elo-mobiliarios-7',
        'consultor-club-elo-mobiliarios-8',
        'consultor-club-elo-mobiliarios-9',
        'consultor-club-elo-mobiliarios-10',
        'consultor-club-elo-mobiliarios-11',
        'consultor-club-elo-mobiliarios-12',
        'consultor-club-elo-mobiliarios-13',
        'consultor-club-elo-mobiliarios-14',
        'consultor-club-elo-mobiliarios-15',
        'consultor-club-elo-mobiliarios-16',
        'consultor-club-elo-mobiliarios-17',
        'consultor-club-elo-mobiliarios-18',
        'consultor-club-elo-mobiliarios-19',
        'consultor-club-elo-mobiliarios-20',
        'consultor-club-elo-mobiliarios-21',
        'consultor-club-elo-mobiliarios-22',
        'consultor-club-elo-mobiliarios-23',
      ]
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true
    },
    onSlideEnd() {
      this.sliding = false
    },
  },
  mounted() {
  },
  computed: {
    monitorWidth: function() {
      var width = screen.width;
      return width;
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Sobre') }}</h4>
        </div>
      </div>
    </div>

    <b-carousel
      id="carousel"
      class="rounded text-center"
      v-model="slide"
      :interval="0"
      controls
      indicators
      background="#1c1a22"
      img-height="350"
      style="text-shadow: 1px 1px 2px #333;"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide v-for="(apn,key) in apns" :key="key" class="rounded">
        <template v-slot:img>
          <div v-if="monitorWidth > 600">
            <img class="slide rounded" :src="require('@/assets/images/apn/desktop/' + apn + '.jpg')" />
          </div>
          <div v-else>
            <img class="slide rounded" :src="require('@/assets/images/apn/mobile/' + apn + '.png')" />
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <div class="d-none row justify-content-center">
      <div class="col-xl-6 col-lg-8 col-md-10">
        <div class="intro card">
          <div class="card-body py-5 text-center">
            <div class="mb-4">
              <img style="height:140px;" src="@/assets/images/logo.png" />
            </div>
            <h5 class="text-default m-0">Uma jornada repleta de conquistas.</h5>
            <h5 class="text-default">Seja o arquiteto do seu sucesso.</h5>
          </div>
        </div>

        <div class="advantages card bg-soft-light">
          <div class="card-body py-0 px-5">
            <div class="row">
              <div class="col-md-5">
                <div class="row bg-white p-2 text-center">
                  <div class="col-6 col-md-12"><img class="img-fluid py-2" style="max-height: 100px;" src="@/assets/images/about/advantages-1.png" /></div>
                  <div class="col-6 col-md-12"><img class="img-fluid py-2" style="max-height: 100px;" src="@/assets/images/about/advantages-2.png" /></div>
                  <div class="col-6 col-md-12"><img class="img-fluid py-2" style="max-height: 100px;" src="@/assets/images/about/advantages-3.png" /></div>
                  <div class="col-6 col-md-12"><img class="img-fluid py-2" style="max-height: 100px;" src="@/assets/images/about/advantages-4.png" /></div>
                </div>
              </div>
              <div class="col-md-7 pt-5 pl-md-5">
                <div class="mb-4 text-center">
                  <img style="height:100px;" src="@/assets/images/logo.png" />
                </div>
                <h5 class="text-default">Converta seu esforço em recompensas e prêmios.</h5>
                <p class="font-size-15">O programa de vantagens da Elo Mobiliários Consultor Club premia suas indicações com prêmios exclusivos.</p>
              </div>
            </div>
          </div>
        </div>

        <div class="faq card bg-soft-light">
          <div class="card-body p-5">
            <h4 class="mb-4 text-default text-center">Dúvidas Frequentes</h4>
            <div class="item bg-white">
              <h5 class="m-0 font-size-16">1 – Quais os serviços que posso indicar?</h5>
            </div>
            <div class="item bg-white">
              <h5 class="m-0 font-size-16">2 – Quando posso solicitar o resgate?</h5>
            </div>
            <div class="item bg-white">
              <h5 class="m-0 font-size-16">3 – Posso receber os prêmios em dinheiro?</h5>
            </div>
            <div class="item bg-white">
              <h5 class="m-0 font-size-16">4 – Quantos pontos ganho por indicar os clientes?</h5>
            </div>
            <div class="item bg-white">
              <h5 class="m-0 font-size-16">5 – Se o cliente não comprar, quantos pontos eu ganho?</h5>
            </div>
            <div class="item bg-white">
              <h5 class="m-0 font-size-16">6 – Em quanto tempo recebo minhas comissão após a solicitação de resgate?</h5>
            </div>
          </div>
        </div>

        <div class="points card bg-soft-info">
          <div class="man-1 card-body pt-5 px-5 pb-0">
            <h4 class="mb-4 text-center">Tabela de Pontuação</h4>

            <div class="row text-center">
              <div class="col-8 p-0">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">Indicação de Cliente</h5>
                </div>
              </div>
              <div class="col-4 p-0 pl-1">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">10 pontos</h5>
                </div>
              </div>
            </div>

            <div class="row text-center">
              <div class="col-8 p-0">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">Cliente Visitou a Loja</h5>
                </div>
              </div>
              <div class="col-4 p-0 pl-1">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">100 pontos</h5>
                </div>
              </div>
            </div>

            <div class="row text-center">
              <div class="col-8 p-0">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">Cliente Comprou</h5>
                </div>
              </div>
              <div class="col-4 p-0 pl-1">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">1.000 pontos</h5>
                </div>
              </div>
            </div>

            <div class="row text-center">
              <div class="col-8 p-0">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">Comissão Sobre a Compra</h5>
                </div>
              </div>
              <div class="col-4 p-0 pl-1">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">5%</h5>
                </div>
              </div>
            </div>

            <div class="row pb-4">
              <div class="col-md-7 mt-5 mb-4 text-center">
                <h5 class="mb-5">Abra as portas para o futuro e seja recompensado por sua confiança na Elo Mobiliários.</h5>
                <img style="height:100px;" src="@/assets/images/logo.png" />
              </div>
            </div>
          </div>
        </div>

        <div class="premiation card bg-soft-warning">
          <div class="man-2 card-body pt-5 px-5 pb-0">
            <h4 class="mb-4 text-center">Prêmios Por Pontuação</h4>

            <div class="d-flex text-center">
              <div class="icon bg-dark p-0">
                <i class="bx bx-dollar font-size-20 p-0 text-white"></i>
              </div>
              <div class="text flex-fill p-0 mx-2">
                <div class="item bg-white">
                  <h5 class="m-0 font-size-16">Dinheiro</h5>
                </div>
              </div>
              <div class="value p-0">
                <div class="item bg-white">
                  <h5 class="m-0 font-size-16">500 pontos</h5>
                </div>
              </div>
            </div>

            <div class="d-flex text-center">
              <div class="icon bg-dark p-0">
                <i class="bx bx-mobile-alt font-size-20 p-0 text-white"></i>
              </div>
              <div class="text flex-fill p-0 mx-2">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">iPhone</h5>
                </div>
              </div>
              <div class="value p-0">
                <div class="item bg-white px-2">
                  <h5 class="m-0 font-size-16">5.000 pontos</h5>
                </div>
              </div>
            </div>

            <div class="d-flex text-center">
              <div class="icon bg-dark p-0">
                <i class="bx bxs-plane-alt font-size-20 p-0 text-white"></i>
              </div>
              <div class="text flex-fill p-0 mx-2">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">Viagem</h5>
                </div>
              </div>
              <div class="value p-0">
                <div class="item bg-white px-2">
                  <h5 class="m-0 font-size-16">10.000 pontos</h5>
                </div>
              </div>
            </div>
            <div class="d-flex text-center">
              <div class="icon bg-dark p-0">
                <i class="bx bx-car font-size-20 p-0 text-white"></i>
              </div>
              <div class="text flex-fill p-0 mx-2">
                <div class="item bg-white px-0">
                  <h5 class="m-0 font-size-16">Carro</h5>
                </div>
              </div>
              <div class="value p-0">
                <div class="item bg-white px-2">
                  <h5 class="m-0 font-size-16">500.000 pontos</h5>
                </div>
              </div>
            </div>

            <div class="row pb-4">
              <div class="col-md-7 mt-5 mb-5 text-center">
                <h5 class="mb-5">Reconhecemos o seu poder de conexão e premiamos o seu sucesso a cada cliente indicado.</h5>
                <img style="height:100px;" src="@/assets/images/logo.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.slide {
  width: 100%;
}

.icon {
  border-radius: 1000px;
  width: 50px !important;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon i {

}
.item {
  border-radius: 50px;
  margin: 0 0 10px;
  padding: 15px 25px;
}

.value {
  width: 160px;
}


@media (min-width: 540px) {
  .slide {
    width: auto;
    height: 80vh;
  }
  .man-1 {
    background: url('~@/assets/images/about/man-1.png') no-repeat right 30px bottom;
    background-size: 300px;
  }
  .man-2 {
    background: url('~@/assets/images/about/man-2.png') no-repeat right 30px bottom;
    background-size: 300px;
  }
}

@media (max-width: 540px) {
  .item {
    padding: 15px 20px;
  }
  .item h5 {
    font-size: 12px !important;
  }

  .advantages .card-body {
    padding: 25px 35px !important;
  }
  .faq .card-body {
    padding-left: 30px !important;
    padding-right: 30px !important;
  }

}
</style>
